import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { VTimePicker } from 'vuetify/labs/VTimePicker'

import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    VTimePicker
  },
  theme: {
    defaultTheme: 'emergclinic',

    themes: {
      emergclinic: {
        dark: false,
        colors: {
          primary: '#69d2e8',
          'primary-50': '#eefbfd',
          secondary: '#e988c4',
          gold: '#FFC700',
          'secondary-blue': '#d6edfb',
          heading: '#444444',
          'ec-black': '#21272A',
          'navy-blue': '#516F84',
          'summer-green': '#9DB8A5',
          'alto-200': '#d9d9d9',
          'ec-light-gray': '#F1F2F7',
          'ec-light-black': '#273240',
          'ec-light-deep-blue': '#717890',
          'ghost-300': '#797b81',
          'daintree-950': '#082431',
          'ec-gray': '#CBCBCB',
          'mine-shaft-900': '#323232',
          'blue-500': '#3B82F6',
          'ec-red': '#F37575',
          'ec-purple': '#9069EC',
          'ec-sharp-green': '#34C759',
          'ec-green': '#2D9848',
          'ec-light-green': '#DCFFE5'
        }
      }
    }
  },
  defaults: {
    global: {},
    VBtn: {
      rounded: 'lg',
    },
  },
})
